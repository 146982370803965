<template>
  <div class="customer_merchant">
      <el-table
          v-if="Memberlist"
          class="table customer-no-border-table"
          height="600"
          :data="Memberlist"
          row-class-name="table-row"
          header-cell-class-name="table-header"
      >
        <el-table-column v-for="v in table" :prop="v.prop" :label="v.label"></el-table-column>
      </el-table>
  </div>
</template>

<script>
export default {
  name: "customer-merchant",
  data(){
    return {
      Memberlist:'', //列表数据
      table:[], //表头
      allPage:'', //所有页数
      nowPage:'', //当前页数
      loading:true, //是否继续加载
    }
  },
  created() {
    if(this.$route.query.name == 'merchant'){
      this.table=[
        {
          prop:'linkage',
          label:'所属商圈'
        },
        {
          prop:'title',
          label:'店铺名称'
        }
      ]
      this.$axios.post('screen/api/get_partner').then(res=>{
        this.Memberlist=res.data.data
        console.log(res.data.data)
      })
    }else if(this.$route.query.name.indexOf('customer') != -1){
      this.table=[
        {
          prop:'mobile',
          label:'手机号'
        },
        {
          prop:'nickname',
          label:'微信名'
        }
      ]
      if(this.$route.query.name=='customerall'){
        this.getMember(1,'all').then((res)=>{
          this.Memberlist=res
          this.allPage = Math.ceil(this.Memberlist.count / 20)
          delete this.Memberlist.count
          this.Memberlist = Object.values(this.Memberlist)
          this.nowPage++;
          //监听触底
          this.$nextTick(() => {
            const el = document.querySelector('.el-table__body-wrapper')
            const offsetHeight = el.offsetHeight;
            el.onscroll = () => {
              const scrollTop = el.scrollTop;
              const scrollHeight = el.scrollHeight;
              if ((offsetHeight + scrollTop) - scrollHeight >= -300 && this.loading ) {
                this.loading = false
                this.nowPage++;
                this.getMember(this.nowPage,'all').then((res)=>{
                  let arr = res
                  delete arr.count
                  arr = Object.values(arr)
                  if(arr.length==10){
                    this.loading = true
                  }
                  this.Memberlist = this.Memberlist.concat(arr)
                  this.loading = true
                })
              }
            };
          });
        })
      }else{
        this.getMember(1,'today').then((res)=>{
          this.Memberlist=res
          this.allPage = Math.ceil(this.Memberlist.count / 20)
          delete this.Memberlist.count
          this.Memberlist = Object.values(this.Memberlist)
          this.nowPage++;
          //监听触底
          this.$nextTick(() => {
            const el = document.querySelector('.el-table__body-wrapper')
            const offsetHeight = el.offsetHeight;
            el.onscroll = () => {
              const scrollTop = el.scrollTop;
              const scrollHeight = el.scrollHeight;
              if ((offsetHeight + scrollTop) - scrollHeight >= -300 && this.loading ) {
                this.loading = false
                this.nowPage++;
                this.getMember(this.nowPage,'today').then((res)=>{
                  let arr = res
                  delete arr.count
                  arr = Object.values(arr)
                  if(arr.length==10){
                    this.loading = true
                  }
                  this.Memberlist = this.Memberlist.concat(arr)
                })
              }
            };
          });
        })
      }
    }
  },
  methods:{
    getMember(page,module){
      return new Promise((resolve, reject) => {
        this.$axios.post('screen/api/get_member',{
          module,
          page,
          pages:20
        }).then(res=>{
          resolve(res.data.data)
        }).catch(err=>{
          reject(err)
        })
      })
    },
  }
}
</script>

<style scoped>
.customer_merchant{
  height: 80%;
  width: 92%;
  margin: 40px auto 0;
  position: relative;
  z-index: 100000;
}
.customer_merchant /deep/.el-table th>.cell{
  text-align: center;
}
.customer_merchant /deep/.el-table .cell{
  text-align: center;
}
.customer_merchant /deep/.el-table__body-wrapper::-webkit-scrollbar,.customer_merchant .table::-webkit-scrollbar{
  display: none;
}
</style>
